<div nz-row class="topbar" nzJustify="end">
    <div nz-col szSpan="4" class="pr-4">
        <nz-badge [nzCount]="unread">
            <button nz-button nzShape="circle" nz-popover nzPopoverTrigger="click"
                [nzPopoverContent]="popoverContentTpl" nzPopoverPlacement="bottomRight"
                (nzPopoverVisibleChange)="notificationFeedVisibleChange($event)"
                [(nzPopoverVisible)]="notificationVisible">
                <span nz-icon nzType="bell" nzTheme="outline"></span>
            </button>
        </nz-badge>
        <ng-template #popoverContentTpl>
            <nz-list class="notifcation-feed-popover pl-2 pr-2" [nzHeader]="headerTpl"
                [nzLoading]="loadingNotifications">
                <div class="notification-items">
                    <nz-list-item *ngFor="let notification of notifications" [ngSwitch]="notification.type">
                        <nz-list-item-meta [nzAvatar]="avatarTpl" [nzDescription]="descriptionTpl">
                            <nz-list-item-meta-title>
                                <a nz-tooltip [nzTooltipTitle]="(notification.createdAt | date:'medium')">{{
                                    notification.createdAt | dateAgo: '' }}</a>
                            </nz-list-item-meta-title>
                            <ng-template #avatarTpl>
                                <nz-avatar nzIcon="user" [nzSrc]="notification.author?.profileImageUrl">
                                </nz-avatar>
                            </ng-template>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button *ngIf="!notification.read" nzType="text" nzShape="circle"
                                    [nzSize]="'small'" (click)="markAsRead(notification.id)" nz-tooltip
                                    nzTooltipTitle="Mark as read" nzTooltipPlacement="bottom">
                                    <i nz-icon nzType="check-circle">
                                    </i>
                                </button>
                            </nz-list-item-action>
                        </ul>
                        <ng-template #descriptionTpl>
                            <span nz-typography>
                                <strong>
                                    <a nz-tooltip *ngIf="notification.author"
                                        [nzTooltipTitle]="notification.author.email">
                                        {{ notification.author.name }}
                                    </a>
                                    <a nz-tooltip *ngIf="notification.author.id == 0">
                                        System
                                    </a>
                                </strong>
                                <span *ngSwitchCase="'ticket_created'">
                                    created
                                </span>
                                <span *ngSwitchCase="'ticket_updated'">
                                    updated
                                </span>
                                <span *ngSwitchCase="'assign'">
                                    assigned you to
                                </span>
                                <span *ngSwitchCase="'mention'">
                                    mentioned you in
                                </span>
                                <span *ngSwitchCase="'comment'">
                                    commented on
                                </span>
                                <strong>
                                    <a (click)="viewTicket(notification)">Case {{ notification.ticketId |
                                        zeroPadding}}</a>
                                </strong>
                            </span>
                        </ng-template>
                    </nz-list-item>
                    <div nz-list-load-more class="loadmore pt-2 pb-2">
                        <a *ngIf="!loadingMore && havingMore == true" (click)="loadMore()">Load More</a>
                    </div>
                    <nz-list-empty *ngIf="!notifications || notifications.length === 0"></nz-list-empty>
                </div>
            </nz-list>
            <ng-template #headerTpl>
                <div nz-row>
                    <div nz-col nzSpan="16">
                        <div nz-row>
                            <span class="title" nz-typography><strong>Notifications</strong></span><br />
                        </div>
                    </div>
                    <div nz-col nzSpan="8">
                        <div nz-row nzJustify="end">
                            <div nz-col>
                                <a (click)="markAllAsRead()">Mark all as read</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
    <div nz-col szSpan="4" class="pr-4">
        <nz-avatar nzIcon="user" nz-popover (nzPopoverVisibleChange)="userMenuVisibleChange($event)"
            [nzSrc]="sessionUser.profileImageUrl" [nzPopoverContent]="userMenu" nzPopoverPlacement="bottomRight">
        </nz-avatar>
        <ng-template #userMenu>
            <nz-list>
                <nz-list-header>
                    <span nz-typography><strong>{{sessionUser.name}}</strong></span><br />
                    <span nz-typography nzType="secondary">{{sessionUser.email}}</span>
                </nz-list-header>
                <nz-list-item>
                    <a (click)="profile()">
                        <i nz-icon nzType="user"></i>
                        Profile
                    </a>
                </nz-list-item>
                <nz-list-item>
                    <a (click)="logout()">
                        <i nz-icon nzType="logout"></i>
                        Log Out
                    </a>
                </nz-list-item>
            </nz-list>
        </ng-template>
    </div>
</div>